import React, { useRef, useState } from "react";
import Helmet from "react-helmet";
import Script from "components/script";
import TicketShop from "components/ticket-shop";
import classNames from "classnames";
import { Header } from "components/Header";
import { motion } from "framer-motion";
import { MotionImage } from "components/MotionImage";
import { useInView } from "hooks/useInView";
import { PageHeader } from "components/PageHeader";

import { Page } from "components/Page";

const IndexPage = () => {
  const photosRef = useRef(null);
  const photosInView = useInView(photosRef, {
    threshold: 0,
  });

  return (
    <Page>
      <Helmet
        bodyAttributes={{
          class: "leading-normal tracking-normal text-white gradient",
        }}
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Uncle Teo PR"}
        meta={[
          {
            name: `description`,
            content: "Uncle Teo PR",
          },
          {
            property: `og:url`,
            content: `https://uncle-teo.web.app`,
          },
          {
            property: `og:title`,
            content: "Uncle Teo PR",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: ``,
          },
        ]}
      />
      <PageHeader imageSrc="hp-hero.jpg">
        <motion.img
          initial={{ skewX: 10, skewY: 10, rotateX: 35, opacity: 0 }}
          animate={{
            skewX: 0,
            skewY: 0,
            rotateX: 0,
            opacity: 1,
            transition: { delay: 0.5, duration: 1 },
          }}
          className="w-56 pb-6"
          src="logo.png"
          alt=""
        />
        <motion.p
          initial={{ y: 10, opacity: 0, rotateX: 35 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { delay: 1, duration: 1 },
          }}
          className="max-w-xl"
          style={{ fontSize: "1.5rem" }}
        >
          We are Uncle TEO Promotions, an innovative, meticulous and creative
          international events company based in Leicester
        </motion.p>
      </PageHeader>

      <section className="container mx-auto text-center py-6 pb-64 -mt-8">
        <div className="flex flex-col items-center md:flex-row" ref={photosRef}>
          <MotionImage
            initial={{ scale: 1.1, opacity: 0, y: 40, rotate: -3 }}
            animate={
              photosInView
                ? {
                    opacity: 1,
                    y: 0,
                    rotate: -3,
                    scale: 1.1,
                    transition: { delay: 0.5, duration: 0.8 },
                  }
                : { scale: 1.1, opacity: 0, y: 40, rotate: -3 }
            }
            className="w-10/12 md:w-4/12 shadow-lg"
            src="home1.jpg"
          />
          <MotionImage
            initial={{ scale: 1.1, opacity: 0, y: 40, rotate: 12 }}
            animate={
              photosInView
                ? {
                    opacity: 1,
                    y: 0,
                    scale: 1.1,
                    rotate: 12,
                    transition: { delay: 0.6, duration: 0.8 },
                  }
                : { scale: 1.1, opacity: 0, y: 40, rotate: 12 }
            }
            className="w-10/12 md:w-4/12 shadow-lg"
            src="home2.jpg"
          />
          <MotionImage
            initial={{ scale: 1.1, opacity: 0, y: 40, rotate: -12 }}
            animate={
              photosInView
                ? {
                    opacity: 1,
                    y: 0,
                    scale: 1.1,
                    rotate: -12,
                    transition: { delay: 0.7, duration: 0.8 },
                  }
                : { scale: 1.1, opacity: 0, y: 40, rotate: -12 }
            }
            className="w-10/12 md:w-4/12 shadow-lg"
            src="home3.jpg"
          />
        </div>
      </section>
      <section>
        <div className="container mx-auto text-center max-w-6xl mb-32 mt-48">
          <TicketShop
            data-next-event-heading=""
            scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="5e098103-01d2-4f01-9e47-6638cbd3adb3" data-fixr-theme="dark"></script>`}
          />
        </div>
      </section>
    </Page>
  );
};

export default IndexPage;
